import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Contact from '@fathomtech/nualang-ui-components/dist/Forms/Contact/Contact';
import Snackbar from '@fathomtech/nualang-ui-components/dist/Misc/Snackbar';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import queryString from 'query-string';
import { contactEmail } from '../../utils/api/Notifications';
import Section from '../../components/Section';
import Layout from '../../layouts/Default';
import demo from '../../../content/demo/demo.yaml';
import NualasLineUp from '../../../static/img/nualas.svg';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: theme.spacing(3),
}));

const NualasHeroImg = styled('img')(({ theme }) => ({
  maxHeight: '200px',
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));

const formatInitialValues = (qsParams) => {
  const { freeTrial, newsAndUpdates, email } = qsParams;
  return {
    email: email || '',
    freeTrial: freeTrial === 'true',
    requestDemo: true,
    newsAndUpdates: newsAndUpdates === 'true',
  };
};

function Demo(props) {
  const {
    location,
    data: {
      site: {
        siteMetadata: { title },
      },
    },
  } = props;
  const { t } = useTranslation();
  const qsParams = queryString.parse(location.search);
  const [submitted, setSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const initialValues = formatInitialValues(qsParams);

  const handleSubmit = async (values) => {
    if (!values.message) {
      values.message = 'No Message';
    }
    const response = await contactEmail(values);

    if (response.MessageId) {
      setSubmitted(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setSnackbarOpen(true);
    }
  };

  return (
    <Layout>
      <Helmet title={`Request a Demo | ${title}`} />
      <Section>
        <React.Fragment>
          <Root>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <Box p={2}>
                  <Typography variant="h4" gutterBottom>
                    {demo.title}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {demo.description}
                  </Typography>
                  <NualasHeroImg
                    src={NualasLineUp}
                    alt="NualasLineUp"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Contact
                  t={t}
                  initialValues={initialValues}
                  handleSubmit={handleSubmit}
                  submitted={submitted}
                  disableCheckboxes
                  id="demo-form"
                />
              </Grid>
            </Grid>
          </Root>
          <Snackbar
            t={t}
            isSnackbarOpen={snackbarOpen}
            closeSnackbar={() => setSnackbarOpen(false)}
            snackbarMessage={t('problem_sending_contact_form')}
            snackbarType={'error'}
          />
        </React.Fragment>
      </Section>
    </Layout>
  );
}

Demo.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export default Demo;

export const demoPageQuery = graphql`
  query DemoQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
